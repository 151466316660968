import React from "react";
import { Typography, Box, Grid, styled } from '@material-ui/core';
// Customizable Area Start
//@ts-ignore
import { motion as m } from 'framer-motion'
import { baseURL } from '../../../blocks/bulkuploading/src/BulkUploadingController';
//@ts-ignore
import {Helmet} from "react-helmet";
// Customizable Area End


import DiscoverController, { Props } from "./DiscoverController";
import { membershipDiary } from "./assets";
import { Process, Carousel, FeatureSection, FeaturewithImageSection } from "../../../components/src/shared";
import FixedText from "../../../components/src/shared/FixedText";
import { HTMLParser } from "../../../components/src/utils/html-parser";

export default class Discover extends DiscoverController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleResize = this.handleResize.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start

  headings(head: any, foot?: boolean) {

    const getLargestTextNumber = () => {
      if(foot) {
        return head.reduce((acc:any, curr:any) => curr.attributes?.footline?.length > acc ?  curr.attributes.footline.length : acc,0)
      } else {
        return head?.reduce((acc:any, curr:any) => curr.attributes?.headline?.length > acc ?  curr.attributes.headline.length : acc,0)
      }
    }

    const isMobile = this.state.windowWidth < 960
    const ml = isMobile ? "40px" : "150px"
    const largestTextNumber = getLargestTextNumber()
    const fz = ((this.state.windowWidth-60)/largestTextNumber > 50 || (this.state.windowWidth-60)/largestTextNumber === 0) ? 50 : (this.state.windowWidth-60)/largestTextNumber
    
    const myStyle = isMobile ? { paddingTop: 136, paddingBottom: 42 } : { height: foot ? 'auto' : '100vh', paddingTop:"200px", paddingBottom:"200px" }
    return (
      <Box className="rootContainer" style={myStyle}>
        <Box>
          {
            head?.sort((a:any,b:any) => parseInt(a.id) - parseInt(b.id)).map((item: any, index: number) => {
              return (
                <Typography variant="h1" color={index % 2 === 0 ? "secondary" : "initial"} key={item} style={{ fontWeight: 400, whiteSpace:"nowrap", marginLeft: index % 2 === 0 ? "0px" : ml, fontSize:fz }}>{foot ? item?.attributes?.footline : item?.attributes?.headline}</Typography>
              )
            })
          }
        </Box>
      </Box>
    )
  }

  wrapperFeature(content: any) {
    const Container = styled('div')({
      "& #feature-section": {
        "@media only screen and (max-width: 768px)": {
          paddingTop: 62 - 20
        }
      },
      "& #subHeading": {
        "@media only screen and (max-width: 768px)": {
          paddingTop: 42 - 25,
        }
      }
    })

    return <Container>{content}</Container>
  }

  wrapperMembers(content: any) {
    const Container = styled('div')({
      "& #container": {
        "@media only screen and (max-width: 960px)": {
          marginTop: 84
        }
      },
      "& #subHeading": {
        "@media only screen and (max-width: 768px)": {
          marginTop: 42,
          marginBottom: 20
        }
      },
      "& #phrase": {
        "@media only screen and (max-width: 1200px)": {
          "& .MuiTypography-h1": {
            // fontSize: 36
          },
        },
        "@media only screen and (max-width: 768px)": {
          paddingBottom: 42
        }
      }
    })

    return <Container>{content}</Container>
  }

  wrapperProcess(content: any) {
    const Container = styled('div')({
      "@media only screen and (max-width: 959px)": {
        "& .MuiTypography-h2": {
          fontSize: 20
        }
      },
      "@media only screen and (max-width: 768px)": {
        "& hr": {
          height: "100px !important"
        },
        "& #process-content": {
          paddingTop: 62,
          paddingBottom: 0
        }
      }
    })

    return <Container>{content}</Container>
  }

  wrapperFounders(content: any) {
    const Container = styled('div')({      
      "@media only screen and (max-width: 768px)": {
        "& #feature-section": {
          paddingTop: 60,
          paddingBottom: 26
        }
      }
    })

    return <Container>{content}</Container>
  }

  wrapperBottomLine(content: any) {
    const Container = styled('div')({
      "@media only screen and (max-width: 768px)": {
        "& > .MuiBox-root": {
          paddingBottom: "160px !important"
        }
      }
    })

    return <Container>{content}</Container>
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const isMobile = window.outerWidth < 768
    return (
      <div style={{ overflowX: "hidden" }}>
        <Helmet>
            <title>Global Discover: Discover Luxury Travel with Private Jet Bookings</title>
            <meta charSet="utf-8" />
            <meta name="description" content="Step into the world of ultimate luxury and convenience with Global's \'Discover\' page. Here, you'll embark on a journey that transcends ordinary travel, giving you access to an exclusive collection of private jets ready to take you anywhere your dreams desire. Immerse yourself in a curated selection of elite aircraft, each meticulously detailed and designed to offer an unparalleled flying experience. Whether you're seeking a quick escape to a tropical paradise or planning a business venture that demands efficiency and comfort, our comprehensive range of private jet options awaits your exploration. From sleek and modern interiors to cutting-edge amenities, you'll find every detail catered to your utmost satisfaction. Our user-friendly interface empowers you to browse, compare, and book with ease, ensuring that your travel aspirations become a seamless reality. Discover a new standard of personalised travel that transcends boundaries and redefines the art of flying. Global's \'Discover\' page is your gateway to a world where opulence meets efficiency, and where your journey is as remarkable as the destination itself. Your adventure begins here – start exploring the future of travel today." />
        </Helmet>
        <FixedText text="DISCOVER" />
        <m.div
          initial={{
            x: "100%"
          }}
          animate={{
            x: "0%"
          }}
          transition={{
            duration: 0.75,
            ease: "easeOut"
          }}
        >

          {this.headings(this.state.headLine)}
          <Box className="hide-clock-in-this-container" sx={this.style.imageContainer} style={{ backgroundImage: `url('${baseURL}${this.state.mainContent?.image?.trim()}')` }}></Box>
          {
            this.wrapperFeature(
              <FeatureSection
                plus
                heading={this.state.mainContent?.title}
                subHeading={this.state.mainContent?.heading}
                para1={this.state.mainContent?.description}
                para2={""}
              />
            )
          }
          <Box sx={this.style.imageOverlay} className='hide-clock-in-this-container' style={{ backgroundImage: `url('${baseURL}${this.state.secondaryContent?.image?.trim()}')` }}>
            <Box style={{ width: "70%", maxWidth: "700px" }}>
              <Typography variant="h1" color="primary" style={{ fontWeight: 400, textAlign: "left" }}>{this.state?.secondaryContent?.title[0]?.attributes?.title}</Typography>
              <Typography variant="h1" style={{ fontWeight: 400, textAlign: "right", color: "white" }}>{this.state?.secondaryContent?.title[1]?.attributes?.title}</Typography>
            </Box>
          </Box>

          {
            this.wrapperMembers(
              <FeaturewithImageSection
                heading={this.state.membership?.title}
                subHeading={this.state.membership?.heading}
                para1={this.state.membership?.description}
                image={`${baseURL}${this.state.membership?.image?.trim()}`}
                accordian={this.state.membership?.points}
              />
            )}
          {
            this.wrapperProcess(
              <Process
                heading={this.state.fleets?.title}
                subHeading="OUR FLEET"
                process={this.state.fleets?.fleet}
                hideSwipeTextOnMobile
                multipara
              />
            )
          }

          <Box className="rootContainer" sx={{ padding: { sm: "20px 40px", md: "20px 40px" } }}>
            <Box sx={{ width: "100%", borderBottom: "1px solid #2C62E0", padding: !isMobile ? "50px 0px" : 'unset', paddingBottom: isMobile ? 20 : 30, paddingTop: isMobile ? 12 : 0 }}>
              <Typography variant="h5" color="primary" style={{ letterSpacing: "4px", fontWeight: 600 }}>{this.state.about?.title}</Typography>
            </Box>
            {this.state.about?.content?.map((item: any, index: number) => {
              return (
                <Box key={item.attributes.about_content_titles.data[0].attributes.title} sx={{ width: "100%", borderBottom: "1px solid #2C62E0", padding: isMobile ? (index === 0 ? '22px 0px 42px 0px' : '42px 0px') : "60px 0px 80px 0px" }}>
                  <Grid container justifyContent="space-between">
                    <Grid item sm={12} md={6}>
                      <Typography variant="h1" color="secondary" style={{ fontWeight: 400, margin: "20px 0px" }}>
                        {
                          item.attributes.about_content_titles.data.sort((a:any,b:any) => parseInt(a.id) - parseInt(b.id)).map((item:any, index:number) => {
                            return(
                              index===0 ? 
                              <span key={item.attributes.title}>{`${item.attributes.title} `}</span> :
                              <span key={item.attributes.title} style={{ color: "#000000" }}>{`${item.attributes.title} `}</span>
                            )
                          })
                        }
                      </Typography>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Typography variant="h3" style={{ textAlign: "justify" }}>
                        <HTMLParser content={item.attributes.description} />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )
            })
            }
          </Box>

          {this.wrapperFounders(
            <FeatureSection
              heading={this.state.founderContent?.title}
              para1={this.state.founderContent?.description}
              para2={""}
            />
          )}

          <Carousel items={this.state.founders} />
          {this.state.footLine && this.wrapperBottomLine(this.headings(this.state.footLine, true))}
        </m.div>
      </div>
    );
    // Customizable Area End
  }
  // Customizable Area Start   
  style = {
    fixedContainer: {
      position: 'fixed',
      top: 400,
      left: 70,
      zIndex: 10
    } as React.CSSProperties,
    fixedText: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "1.2rem",
      lineHeight: "28px",
      letterSpacing: "0.2em",
      color: "#2C62E0",
    } as React.CSSProperties,
    wrapper: {
      width: "95%"
    } as React.CSSProperties,
    imageContainer: {
      width: "100%",
      height: "100vh",
      borderRadius: "0px 0px 20px 20px",

      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"
    } as React.CSSProperties,
    imageOverlay: {
      width: "100%",
      height: "800px",
      margin: "auto",
      borderRadius: "20px",
      backGroundColor: "red",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      display: "flex",
      justifyContent: "center",
      paddingBottom: "30px",
      alignItems: { xs: "center", md: "flex-end" },
      maxWidth: "1400px",
      boxSizing: "border-box"
    },
    imageMembership: {
      width: "100%",
      minHeight: "600px",
      height: "100%",
      borderRadius: "20px",
      backgroundImage: `url(${membershipDiary})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"
    } as React.CSSProperties
  }
  // Customizable Area End
}
