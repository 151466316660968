import React from "react";
import { Typography, Box, Grid, styled } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HeadingText, Process, ProcessItem } from "../../../components/src/shared";
import { motion as m } from 'framer-motion'
import { ImpactResponse } from "../../../components/src/types";
import clsx from 'clsx';
import { HTMLParser } from "../../../components/src/utils/html-parser";
import { useImpactPageContent } from '../../../components/src/api/hooks/impact.hooks';
import FixedText from "../../../components/src/shared/FixedText";
//@ts-ignore
import {Helmet} from "react-helmet";

const { baseURL } = require("../../../framework/src/config");

const DEFAULT_IMPACT_RESPONSE: ImpactResponse = {
  data: [
    {
      id: '1',
      type: 'impact',
      attributes: {
        title: '',
        image: '',
        heading: '',
        description: '',
        timeline_title: '',
        impact_timelines: { data: [] },
      },
    }
  ]
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    gap: "50px",
    padding: "180px 0px",
    width: "100%",
    paddingBottom: "0px",
    '& p': {
      fontWeight: 400,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 96 - 20
    }
  },
  imageContainer: {
    width: "100%",
    height: "100vh",
    borderRadius: "0px 0px 20px 20px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  impactText: {
    fontWeight: 400,
    fontSize: '1.4rem',
    [theme.breakpoints.up('sm')]: {
      visibility: 'hidden'
    }
  },
  header: {
    height: "50vh",
    padding: '20px',

    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    marginTop: '180px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '100%',
      height: '20vh',
      paddingTop: '20vh',
      paddingBottom: '20vh',
      gridGap: '20px',
      marginTop: '100px'
    }
  },
}));

export function ImpactContent() {

  const { data: impactResponse } = useImpactPageContent();
  const classes = useStyles();

  const {
    data: [
      {
        attributes: {
          title: header1,
          heading: header2,
          description,
          image,
          timeline_title: timelineTitle,
          impact_timelines: { data: timelines }
        }
      }
    ]
  } = impactResponse?.data && impactResponse.data.length > 0 ? impactResponse : DEFAULT_IMPACT_RESPONSE

  const process: ProcessItem[] = timelines
    .map(({ attributes: { title, heading, description } }) => ({
      name: title,
      discription: heading,
      para: description,
    }));

  const isMobile = window.outerWidth < 768

  return (
    <div style={{ overflowX: "hidden" }}>
        <Helmet>
            <title>Global Impact: Our Commitment to Carbon Neutrality</title>
            <meta charSet="utf-8" />
            <meta name="description" content="At Global, we take our responsibility to the environment seriously. Through our Sustainability Partners and their impactful services, we are dedicated to offering our travelers a meaningful way to contribute to a sustainable future. Our Impact page highlights our unwavering commitment to carbon neutrality and empowers you to make a real difference. OUR COMMITMENT TO CARBON NEUTRALITY: With the world is climate in mind, we are proud to provide you the opportunity to transform your travel into a force for good. Through carbon offsets, we enable you to neutralize the carbon footprint of your flights. Each offset represents a ton of greenhouse gas emissions that has been avoided or reduced, contributing to a cleaner atmosphere. HOW IT WORKS: It is simple – by partnering with projects around the globe that actively reduce carbon emissions, we make it easy for you to offset the environmental impact of your flights. Purchasing carbon offsets translates into supporting initiatives that range from reforestation to renewable energy, all aimed at counterbalancing your carbon footprint and creating a positive environmental legacy. JOIN US IN MAKING A CHANGE: Together, we can turn travel into a transformative force for the planet. Our Impact page invites you to be a part of this movement. Whether you are a conscious traveler or a responsible business, you have the power to contribute to a more sustainable world. Make a lasting impact by choosing carbon neutrality and aligning your journeys with environmental stewardship. Take action now – explore our Impact page and learn how you can travel the world with purpose, knowing that each trip is a step toward a greener future. Your journey matters, and so does the planet we share." />
        </Helmet>
      {!isMobile && <FixedText text={"IMPACT"} />}

      <m.div
        initial={{
          x: "100%"
        }}
        animate={{
          x: "0%"
        }}
        transition={{
          duration: 0.75,
          ease: "easeOut"
        }}
      >

        <HeadingContainer>
          <Box id='headings' className={clsx(classes?.header)}>
            {isMobile && <FixedText text={"IMPACT"} />}
            <HeadingText alternateColors heading={[header1, header2]} />
          </Box>
        </HeadingContainer>

        <Box
          className={clsx(classes?.imageContainer, 'hide-clock-in-this-container')}
          style={{
            backgroundImage: `url(${baseURL}${image.trim()})`,
          }}>
        </Box>


        <Grid container>
          <Grid item xs={12} md={12}>

            <Box className="rootContainer">
              <Box className={classes?.wrapper}>
                <Typography variant="h3" style={{ textAlign: "justify" }}>
                  <HTMLParser content={description} />
                </Typography>
              </Box>
            </Box>
            <Box style={{ padding: !isMobile ? "70px 0px" : '' }}>
              <Process subHeading={timelineTitle} process={process} />
            </Box>
          </Grid>
        </Grid>


      </m.div>
    </div>
  );
}

const HeadingContainer = styled('div')({
  "& > #headings": {
    "@media only screen and (max-width:768px)": {
      height: 'unset',
      paddingBottom: 84
    }
  },
  // @ts-ignore
  "& #floating-fixed-text": {
    position: 'unset !important',
    width: '100%'
  }
})
