// Customizable Area Start
import React, { useState, ReactNode, useEffect } from "react";
import { Typography, Box } from '@material-ui/core';
import { HeadingText } from "./HeadingText";
import { makeStyles } from '@material-ui/core/styles';
import { JetZeroTabs } from '../design-system';
import { HideContainer } from "./HideContainer";
import { VerticalSpacer } from "./Spacer";
import { HTMLParser } from '../utils/html-parser';
import { baseURL } from '../../../blocks/bulkuploading/src/BulkUploadingController';


export interface ProcessItem {
  name: string,
  discription: string,
  para: string,
  image?: string,
  details?: { [key: string]: string | number },
  filter?: ReactNode | any
}

interface Props {
  heading?: string[],
  subHeading: string,
  process: ProcessItem[]
  hideSwipeTextOnMobile?: boolean,
  multipara?: boolean,
  classed?: boolean,
  children?: ({ selectedIndex }: { selectedIndex: number }) => ReactNode,
  isAccountPage?: boolean,
  setPreactivatedIndex?: React.Dispatch<React.SetStateAction<number>>,
  preActivated?: number
}


export function Process({ heading, subHeading, process, hideSwipeTextOnMobile, multipara, classed, children, isAccountPage, setPreactivatedIndex, preActivated }: Props) {

  const useStyles = makeStyles((theme) => ({
    wrapper: {
      width: '100%',
      // hide the scroll buttons for desktop  when disabled
      '& .MuiTabs-scrollButtonsDesktop.Mui-disabled:nth-child(1)': {
        display: 'none',
        transition: 'all 0.3s',
      },
      [theme.breakpoints.down('sm')]: {
        width: "100%"
      }
    },
    gridContainer: {
      position: 'relative',
      padding: classed ? "110px 0px 0px 0px" : '40px 0',
      display: 'grid',
      gridTemplateAreas: '"title table" "description table" "image image"',
      gridTemplateColumns: classed ? '90% 1fr' : '60% 1fr',
      gridColumnGap: '30px',
      gridTemplateRows: multipara ? "80px" : '40px',

      [theme.breakpoints.down('sm')]: {
        padding: classed ? "40px 0px 0px 0px" : "40px 0px",
        gridTemplateAreas: '"title" "image" "description" "table"',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'unset',
        gridColumnGap: '15px',
      },
    },
    numberContainer: {
      gridArea: 'number',
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      }
    },
    titlesContainer: {
      gridArea: 'title',
    },
    imageContainer: {
      gridArea: 'image',
      '&>img': {
        maxWidth: '100%',
      },
    },
    descriptionContainer: {
      gridArea: 'description',
      fontWeight: 600,
      paddingTop: classed ? "20px" : "0px",
      [theme.breakpoints.down('sm')]: {
        paddingTop: classed ? "0px" : '20px',
      },
    },
    tableContainer: {
      gridArea: 'table',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '20px',
      },
    },
    titleText: {
      fontWeight: 400,
      textTransform: "uppercase",
      textAlign: "justify",
      display: "flex",
      gap: "20px",
      fontSize: "40px",
      [theme.breakpoints.down('sm')]: {
        fontSize: "20px"
      }
    },
    descriptionText: {
      marginTop: "5px",
      fontWeight: 400,
      textTransform: "uppercase",
      color: "rgba(193,193,193,1)",
      [theme.breakpoints.down('sm')]: {
        textTransform: "lowercase",
        "&:first-letter" : {
          textTransform: "capitalize"
        }
      }
    },
    paranText: {
      marginTop: "5px",
      fontWeight: 400,
      textAlign: "justify"
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      '& td:nth-child(2)': {
        textAlign: 'right',
      },
      '& td': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
    },
    tableKeyText: {
      padding: '10px 0',
      fontWeight: 600,
      textTransform: "uppercase"
    },
    tableValueText: {
      fontWeight: 600,
      textTransform: "uppercase",
    },
    numberText: {
      fontWeight: 600,
      textTransform: "uppercase",
      display: 'none',      
      [theme.breakpoints.down('sm')]: {
        display: classed ? "none" : 'block',
      }
    },
    exploreText: {
      display: "none",
      fontSize: "10px",
      color: "#C1C1C1",
      fontWeight: 400,
      marginBottom: "15px",
      [theme.breakpoints.down('sm')]: {
        display: "block",
      },
    },
    classed: {
      width: "100%",
      boxSizing: "border-box",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px 40px",
      margin: "auto",
      [theme.breakpoints.down('sm')]: {
        padding: "20px 40px"
      }
    }
  }))

  const classes = useStyles();

  const [selectedProcessIndex, setSelectedProcessIndex] = useState<number>(0)

  if (!process.length)
    return null;

  const { name, discription, image, para, details, filter } = process[preActivated ? preActivated : selectedProcessIndex];

  return (
    <>
      <Box className={classed ? `${classes.classed} rootContainer1` : "rootContainer"}>
        <Box className={classes.wrapper}>

          <HideContainer hideWhen={!heading} >
            <HeadingText heading={heading} />
            <VerticalSpacer value={40} />
          </HideContainer>

          <HideContainer hideWhen={Boolean(hideSwipeTextOnMobile)}>
            <Typography className={classes.exploreText}>
              {'SWIPE TO EXPLORE'}
            </Typography>
          </HideContainer>

          <JetZeroTabs
            onClick={classed}
            onChange={setPreactivatedIndex ? setPreactivatedIndex : setSelectedProcessIndex}
            tabsNames={process.map(item => item.name)}
            activeIndex={isAccountPage ? NaN : preActivated ? preActivated : selectedProcessIndex}
            header={subHeading}
          />

          <HideContainer hideWhen={Boolean(isAccountPage)}>
            <div id='process-content' className={classes.gridContainer}>
              <div className={classes.titlesContainer}>

                <Typography style={{ alignItems: 'center', color: "black", textAlign: 'left', fontWeight: 400, textTransform: "uppercase", marginBottom: "5px" }} component="span" className={classes.titleText}>
                  <Typography
                    variant="h3"
                    color="primary"
                    component="span"
                    className={classes.numberText}
                  >
                    {String(preActivated ? preActivated : selectedProcessIndex + 1).padStart(2, '0')}
                  </Typography>
                  {name}
                </Typography>

              </div>

              <div className={classes.imageContainer}>
                <HideContainer hideWhen={!process[preActivated ? preActivated : selectedProcessIndex].image}>
                  <img
                    style={{ paddingTop: "20px" }}
                    src={`${baseURL}${image?.trim()}`}
                    alt={name}
                  />
                </HideContainer>
              </div>

              <div className={classes.descriptionContainer}>

                <Typography variant="h3" style={{ marginBottom: "20px" }} className={multipara ? classes.paranText : classes.descriptionText}>
                  <HTMLParser content={discription} />
                </Typography>
                <Typography variant="h3" className={classes.paranText}>
                  <HTMLParser content={para} />
                </Typography>

              </div>

              {filter && filter}

              {
                !classed &&
                <div className={classes.tableContainer}>

                  <table className={classes.table}>
                    <tbody>
                      {
                        Object.entries(details || {})
                          .map(([key, value], index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <Typography
                                    variant="h4"
                                    className={classes.tableKeyText}
                                  >
                                    {key}
                                  </Typography>
                                </td>
                                <td>
                                  <Typography
                                    variant="h4"
                                    color="primary"
                                    className={classes.tableValueText}>
                                    {
                                      key === "WIFI" || key === "ATTENDANT" ?
                                        value ? "YES" : "NO"
                                        : value
                                    }
                                  </Typography>
                                </td>
                              </tr>
                            )
                          })
                      }
                    </tbody>
                  </table>

                </div>
              }

            </div>
          </HideContainer>
        </Box>
      </Box>
      <HideContainer hideWhen={!children}>
        {children && children({ selectedIndex: preActivated ? preActivated : selectedProcessIndex })}
      </HideContainer></>
  )
}
// Customizable Area End
