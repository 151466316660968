import React from 'react'
import { Typography, Box, TypographyTypeMap } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HideContainer } from './HideContainer';

const useStyles = makeStyles({
  container: {
    width: "fit-content",
  },
  heading1: {
    textAlign: "left",
    fontWeight: 400,
    textTransform: "uppercase"
  },
  heading2: {
    textAlign: "right",
    fontWeight: 400,
    marginLeft: "40px",
    textTransform: "uppercase"
  },
  plus: {
    fontWeight: 400,
    textTransform: "uppercase"
  }
});


const colorsMap: { [key: string]: TypographyTypeMap['props']['color'] } = {
  black: "initial",
  gray: "secondary",
};


export function HeadingText({ heading, plus, alternateColors, } : Readonly<{ heading?: string[], plus?: boolean, alternateColors?: boolean }>) {

  const classes = useStyles();
  const [header1, header2] = heading ?? [];

  const firstColor = alternateColors ? 'gray' : 'black';
  const secondColor = alternateColors || plus ? 'black' : 'gray';


  return (
    <Box className={classes.container}>

      <Typography variant='h1' className={classes.heading1} color={colorsMap[firstColor]}>

        
        {header1}
        

        <HideContainer hideWhen={!plus}>
          <Typography variant='h1' component='span' color="secondary" className={classes.plus}>
            {' +'}
          </Typography>
        </HideContainer>

      </Typography>

      <Typography variant='h1' color={colorsMap[secondColor]} className={classes.heading2}>
        {header2}
      </Typography>

    </Box>
  )
}


