import React, { ReactNode } from 'react';
import { Typography, Box, } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import { motion as m, AnimatePresence } from 'framer-motion'

const useAccordionStyles = makeStyles({
  summaryBox: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "end",
  },
});



export const JetZeroAccordion = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion);



export const JetZeroAccordionSummary = withStyles({
  root: {
    marginBottom: -8,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    padding: "0px"
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);



export const JetZeroAccordionDetails = withStyles({
  root: {
    padding: "16px 0px",
  },
})(MuiAccordionDetails);



export interface AccordionItem {
  summary: string,
  details: string
}



export function JetZeroAccordionList({ list }: { list: AccordionItem[] }) {

  const classes = useAccordionStyles();

  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange = (panel: string) => (_event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <AnimatePresence>
      {
        list?.map((item, index) => {
          return (
            <JetZeroAccordion key={item.details} square expanded={expanded === index.toString()} onChange={handleChange(index.toString())}>
              <JetZeroAccordionSummary>

                <Box className={classes.summaryBox}>

                  <Typography style={{ fontWeight: 600, fontSize: "15px" }}>{item.summary}</Typography>

                  <m.span
                    initial={{
                      rotate: 0
                    }}
                    animate={{
                      rotate: expanded === index.toString() ? 45 : 0
                    }}
                    transition={{
                      duration: .2,
                      ease: "easeInOut"
                    }}
                    exit={{
                      rotate: 0
                    }}
                  ><AddIcon color='primary' /></m.span>
                </Box>
              </JetZeroAccordionSummary>

              <JetZeroAccordionDetails>
                <Typography variant='h3' color="secondary" style={{ fontWeight: 400, textAlign:"justify", fontSize:"15px" }}>{item.details}</Typography>
              </JetZeroAccordionDetails>

            </JetZeroAccordion>
          )
        })
      }
    </AnimatePresence>
  );
}

////////////////////////////////////

const useAccordionStatelessStyles = makeStyles({
  summaryBox: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export function JetZeroAccordionStateless({
  summary,
  details,
}: {
  summary: ReactNode,
  details: ReactNode,
}) {

  const classes = useAccordionStatelessStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (_event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded);
  };


  return (
    <AnimatePresence>
      <JetZeroAccordion square expanded={expanded} onChange={handleChange}>
        <JetZeroAccordionSummary>

          <Box className={classes.summaryBox}>

            {summary}

            <m.span
              initial={{
                rotate: 0
              }}
              animate={{
                rotate: expanded ? 45 : 0
              }}
              transition={{
                duration: .2,
                ease: "easeInOut"
              }}
              exit={{
                rotate: 0
              }}
            >
              <AddIcon color='primary' />
            </m.span>
          </Box>
        </JetZeroAccordionSummary>

        <JetZeroAccordionDetails>
          {details}
        </JetZeroAccordionDetails>

      </JetZeroAccordion>
    </AnimatePresence>
  );
}