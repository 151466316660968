import React, { ReactNode, useEffect, useState } from "react";
import { Typography, useMediaQuery, styled } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link } from "react-router-dom";
import { useRouteInfo } from "../utils/router-context";
import { HideContainer } from "./HideContainer";
import { useNavigate } from '../../../components/src/utils/router-context';
import useOnlineStatus from './GetOnlineStatus'
const IconWhatsApp = require('./images/whatsapp.png')

let links = [
  { title: 'discover', link: '/discover' },
  { title: 'join', link: '/join' },
  { title: 'impact', link: '/impact' },
  { title: 'login', link: '/login' },
];





export function JetZeroTopNav() {


  const [count, refresh] = useState(0)
  const navigate = useNavigate()

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const [open, setOpen] = useState(false);

  const { path, } = useRouteInfo();
  const isAccountPage = path === '/account'

  const isBlackBackgroundPage = true

  const useStyles = makeStyles((theme) => ({
    root: {
      overflow: 'hidden',
    },
    header: {
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100px',
      zIndex: 10,
      background: '#000000',

      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      padding: '55px 110px',
      [theme.breakpoints.down('sm')]: {
        padding: '0px 40px',
        height: "90px",
        background: 'black',
      },
    },
    logo: {
      maxWidth: "55px",
      [theme.breakpoints.down('sm')]: {
        maxWidth: '35px',
      },
    },
    nav: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingRight: "20px"
      },
      '&>*': {
        marginLeft: '80px',
        textDecoration: 'none',
      },
    },
    navOpen: {
      display: 'block',
      padding: '80px 0',
      '&>*': {
        textAlign: 'left',
        textDecoration: 'none',
      },
      '& p': {
        fontSize: '16px',
        marginBottom: '40px',
        marginLeft: "20px"
      },
    },
    navLink: {
      color: 'black',
      textTransform: 'uppercase',
      fontWeight: 600,
      '& span:first-child': {
        paddingRight: '20px'
      },
      '&:hover': {
        color: 'var(--primary-color)',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
      },
    },
    navNumber: {
      color: 'black',
      textTransform: 'uppercase',
      fontWeight: 600,
      '& span:first-child': {
        paddingRight: '20px'
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
      },
    },
    whiteColor: {
      color: 'white',
    },
    activeLink: {
      color: 'var(--primary-color)',
    },
    overlay: {
      height: '100vh',
      width: '50%',
      transform: 'translateX(100%)',
      transition: 'transform 0.4s ease-in-out',
      background: 'white',
      color: 'black',
      zIndex: 100,
      position: 'fixed',
      top: 0,
      right: 0,
    },
    overlayOpen: {
      transform: 'none',
    },
    closeBtn: {
      background: 'transparent',
      border: 'none',
      position: 'absolute',
      top: '36px',
      right: '40px',
      cursor: 'pointer',
    },
    closeIcon: {
      width: "18px",
      [theme.breakpoints.down('sm')]: {
        width: "18px"
      }
    },
    openBtn: {
      cursor: 'pointer',
      background: 'transparent',
      border: 'none',
      padding: "0px"
    },
    invertColors: {
      filter: 'brightness(0) invert(1)',
      width: "18px",
      [theme.breakpoints.down('sm')]: {
        width: "18px"
      }
    },
  }));

  const classes = useStyles();
  const [isLoggedIn, setIsLoggedIn] = useState<any>(false)

  useEffect(()=>{
    setIsLoggedIn(sessionStorage.getItem('token'))
  },[])

  links = links.filter(item => !['login', 'logout', 'my account'].includes(item.title))

  if (isLoggedIn) {
    links = links.filter(item => !['join'].includes(item.title))
    links = [
      ...links,
      { title: 'my account', link: '/account#account' },
      {
        title: 'logout',
        link: '#',
        // @ts-ignore
        onClick: async () => {
          localStorage.clear();
          sessionStorage.clear();
          refresh(count + 1);
          navigate('/login');
        }
      },
    ]
  }
  else {
    links = [
      { title: 'discover', link: '/discover' },
      { title: 'join', link: '/join' },
      { title: 'impact', link: '/impact' },
      { title: 'login', link: '/login' },
    ]
  }

  const NavLinks = (
    <nav
      className={clsx(classes.nav, {
        [classes.navOpen]: open
      })}>
      {
        // @ts-ignore
        links.map(({ title, link, onClick }, index) => (
          <Link to={link} key={title} onClick={onClick} title={title}>
            <Typography
              className={clsx(classes.navLink, {
                [classes.whiteColor]: !open && isBlackBackgroundPage,
                [classes.activeLink]: path == link || isAccountPage && title == "my account",
              })}
            >
              <span className={clsx(classes.navNumber, {
                [classes.whiteColor]: !open && isBlackBackgroundPage,
                [classes.activeLink]: path == link || isAccountPage && title == "my account",
              })}>
                {String(index + 1).padStart(2, '0')}
              </span>
              <span>
                {`${title}`}
              </span>
            </Typography>
          </Link>
        ))
      }
    </nav>
  );

  return (
    <div className={classes.root}>
      <header className={classes.header}>

        <Link title='discover' to="/discover">
          <img
            className={classes.logo}
            src="/assets/jet-zero-logo.svg"
            alt="Jet Zero Reduced" />
        </Link>

        <HideContainer hideWhen={!isMobileScreen}>
          <button className={classes.openBtn} onClick={() => setOpen(true)}>
            <img
              src={"/assets/burger-menu.svg"}
              alt="open menu"
              className={clsx({
                [classes.invertColors]: true,
              })}
            />
          </button>
        </HideContainer>

        <HideContainer hideWhen={isMobileScreen}>
          {NavLinks}
        </HideContainer>

      </header>

      <div className={clsx(classes.overlay, {
        [classes.overlayOpen]: open
      })}>

        <button className={classes.closeBtn} onClick={() => setOpen(false)}>
          <img className={clsx({
            [classes.closeIcon]: true,
          })} src="/assets/close.svg" alt="close menu" />
        </button>

        {NavLinks}

      </div>

    </div>
  );
}

export function TopNavLayout({ children }: { children: ReactNode }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const status = useOnlineStatus()

  const style = {
    backgroundColor: 'yellow',
    letterSpacing: '0.4px',
    textAlign: 'center',
    padding: '2px 0px',
    position: 'fixed',
    width: '100%',
    fontSize: 10,
    zIndex: 12,
  } as React.CSSProperties;

  const { path } = useRouteInfo();

  const isLoginPages =
    path == '/login' ||
    path.split("?")[0] == '/reset-password' ||
    path == '/forgot-password' ||
    path == "/join/thank-you" ||
    path == "/join";

  const showWhatsapp = isLoggedIn && !isLoginPages

  useEffect(() => {
    sessionStorage && setIsLoggedIn(sessionStorage.getItem('token') ? true : false)
  }, [sessionStorage])

  return (
    <>
      {!status && <div style={style}>You're currently offline! Your data will be synced when you back to online!</div>}
      <JetZeroTopNav />      
      {showWhatsapp && <WhatsappIconLink target="_blank" href={encodeURI('https://wa.me/+12192666661?text=I have a query')}><img src={IconWhatsApp} alt='whatsapp icon' /></WhatsappIconLink>}
      {children}
    </>
  );
}

const WhatsappIconLink = styled('a')({
  left: 56,
  zIndex: 10,
  right : 'auto',
  bottom: 56,
  position: 'fixed',
  "& > img": {
    width: 55
  },
  "@media only screen and (max-width: 1150px)": {
    right: 32,
    left : 'auto',
    bottom: 32
  },
  "@media only screen and (max-width: 768px)": {
    right: 24,
    left : 'auto',
    bottom: 24
  }
})
