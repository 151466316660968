import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { HeadingText } from './HeadingText';
import { makeStyles } from '@material-ui/core/styles';
import { JetZeroAccordionList, AccordionItem } from '../design-system';
import { useNavigate } from '../utils/router-context'; import { useState, useEffect } from 'react';
import { HTMLParser } from '../utils/html-parser';
import clsx from 'clsx'


interface Props {
  heading: string[],
  subHeading: string,
  para1: string,
  image: string,
  accordian: AccordionItem[]
}

export function FeaturewithImageSection({ heading, subHeading, para1, image, accordian }: Props) {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }
  const { width } = useWindowDimensions();
  const [layout, setLayout] = useState<boolean>(false)
  useEffect(()=>{
    if(width>1150 && width<1316){
      setLayout(true)
    }else {
      setLayout(false)
    }
  },[width])

  console.log(layout, "Layout")

  const useStyles = makeStyles((theme) => ({
    rootContainer: {
      paddingLeft: "40px",
      width: "100%",
      boxSizing: "border-box",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
      [theme.breakpoints.down('sm')]: {
        paddingLeft: "0px"
      }
    },
    gridContainer: {
      margin: "200px 0px 200px 0px",
      position: 'relative',
      borderLeft: '1px solid',
      borderBottom: '1px solid',
      borderImage: `linear-gradient(to right, ${theme.palette.primary.main} 25%, transparent 25%) 1% 1`,

      display: 'grid',
      gridTemplateAreas: '"text image" "accordion image"',
      gridTemplateColumns: layout ? '67% 33%' : '50% 50%',
      alignItems: 'flex-start',

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '100%',
        border: '0px solid',
        gridTemplateAreas: '"text" "image" "accordion"',
        margin: "800px 0px 80px 0px",
      },
    },
    textArea: {
      gridArea: 'text',
      padding: "20px 66px",      
      [theme.breakpoints.down('sm')]: {
        padding: "20px 40px",
      },
      '& > *': {
        marginBottom: '25px',
      },
    },
    imageArea: {
      gridArea: 'image',
      width: layout ? `${width / 3}px` : `${width / 2 - 27}px`,
      maxWidth: "680px",
      [theme.breakpoints.down('sm')]: {
        width: "100%"
      },
      '& img': {
        width: '100%',
        maxHeight: '800px',
        borderRadius: '20px',
        objectFit: 'cover',
      },
    },
    paddings: {
      padding: "20px 66px",
      [theme.breakpoints.down('sm')]: {
        padding: "20px 40px",
      },
    },
    accordionArea: {
      gridArea: 'accordion',
      minHeight: "300px",
      padding: "20px 66px",
      [theme.breakpoints.down('sm')]: {
        padding: "20px 40px",
        paddingTop: 84
      },
    },
    applyBtn: {
      fontWeight: 600,
      fontSize: '1.4rem',
      position: 'absolute',
      bottom: '-28px',
      left: '28%',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        left: 'auto',
        right: "40px"
      },
    },
    row: {
      display: "none",
      bottom: "-1px",
      height: "1px",
      width: "30%",
      backgroundColor: theme.palette.primary.main,
      margin: "0",
      position: "absolute",
      [theme.breakpoints.down('sm')]: {
        left: "auto",
        display: "block",
        right: "146px"
      },
    },
    arrow: {
      width: "13px",
      height: "13px",
      left: "calc(25% - 15px)",
      bottom: "-7px",
      margin: "0",
      position: "absolute",
      transform:"rotate(45deg)",
      borderRight: `1px solid ${theme.palette.primary.main}`,
      borderTop: `1px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.down('sm')]: {
        left: "auto",
        right: "147px",
        width: "9px",
        height: "9px",
        bottom: "-5px",
      },
    },
    subHeadingText: {
      letterSpacing: "4px",
      fontWeight: 600,
    },
  }));
  const classes = useStyles();

  const navigate = useNavigate();
  const [accordianArr, setAccordianArr] = useState<AccordionItem[]>([])
  useEffect(() => {
    const tempArr = accordian?.map((item: any) => {
      return {
        summary: item?.attributes?.heading,
        details: item?.attributes?.description,
      }
    })

    setAccordianArr(tempArr)
  }, [accordian])

  const [headingArr, setHeadingArr] = useState<string[]>([])
  useEffect(() => {
    const tempArr = heading?.sort((a:any,b:any) => parseInt(a.id) - parseInt(b.id)).map((item: any) => {
      return item.attributes.title
    })
    setHeadingArr(tempArr)
  }, [heading])

  return (
    <div className={`${classes.rootContainer} sizeContainer`}>
      <div id="container" className={`${classes.gridContainer} sizeContainer`}>

        <div id='phrase' className={classes.textArea}>
          <HeadingText heading={headingArr} />

          <Typography
            id='subHeading'
            variant="h4"
            color="primary"
            className={classes.subHeadingText}
          >
            {subHeading}
          </Typography>

          <Typography variant="h3" style={{ textAlign: "justify" }}>
            <HTMLParser content={para1} />
          </Typography>
        </div>

        <div className={clsx(classes.imageArea, 'hide-clock-in-this-container')}>
          <img src={`${image}`} />
        </div>

        <div id='accordion' className={classes.accordionArea}>
          <JetZeroAccordionList list={accordianArr} />
        </div>

        <div className={classes.paddings}>
          <div className={classes.row}></div>
          <div className={classes.arrow}></div>

          <Button
            onClick={() => navigate("join")}
            color="primary"
            variant="text"
            className={classes.applyBtn}
          >
            {'Apply'}
          </Button>
        </div>

      </div>
    </div>
  )
}

