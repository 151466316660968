import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { makeHttpMessage } from '../../../components/src/utils/http-message';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import Founders from "../../../components/src/types/founders";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  founders : Founders[] | undefined
  fleets : any,
  membership : any,
  about : any,
  headLine : any,
  footLine  : any,
  founderContent : any,
  mainContent : any,
  secondaryContent : any,
  contents : any,
  loading : boolean,
  windowWidth : any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DiscoverController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchDiscoverPageDataMsgId : string
  fetchFoundersDataMsgId : string
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      founders : undefined,
      contents : [],
      fleets : {
        title : [""],
        fleet : [
          {
            name : "",
            discription : "",
            para : "",
            image : "",
            details : {
              "REGISTRATION" : "",
              "SEATS" : 0,
              "BEDS" : 0,
              "RANGE" : "",
              "WIFI" : "",
              "ATTENDANT" : "",
              "LUGGAGE" : "",
              "LAVATORY" : 0,
              "CABIN WIDTH" : "",
              "CABIN LENGTH" : "",
              "CABIN HEIGHT" : ""
            }
          }
        ]
      },
      membership : undefined,
      about : undefined,
      headLine : undefined,
      footLine  : undefined,
      founderContent : undefined,
      mainContent : undefined,
      secondaryContent : {
        image : "",
        title : ""
      },
      loading : false,
      windowWidth : window.innerWidth
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId == this.fetchDiscoverPageDataMsgId) {
        if (errorReponse) {
          console.error(errorReponse);
        } else {
          this.setState({
            fleets : {
              title : responseJson?.data?.attributes?.fleet_titles?.data?.sort((a:any,b:any) => parseInt(a.id) - parseInt(b.id)).map((item:any) => item.attributes.title),
              fleet : responseJson?.data?.attributes?.fleets?.data?.map((item:any) => {
                return {
                  "name" : item.attributes.title,
                  "discription" : item.attributes.description,
                  "image" : item.attributes.image,
                  "details" : item.attributes.fleet_details !== "no fleet details available" ? {
                    "REGISTRATION" : item.attributes.fleet_details.data[0].attributes.registration,
                    "SEATS" : item.attributes.fleet_details.data[0].attributes.seats,
                    "BEDS" : item.attributes.fleet_details.data[0].attributes.beds,
                    "RANGE" : item.attributes.fleet_details.data[0].attributes.range,
                    "WIFI" : item.attributes.fleet_details.data[0].attributes.wifi,
                    "ATTENDANT" : item.attributes.fleet_details.data[0].attributes.attendant,
                    "LUGGAGE" : item.attributes.fleet_details.data[0].attributes.luggage,
                    "LAVATORY" : item.attributes.fleet_details.data[0].attributes.lavatory,
                    "CABIN WIDTH" : item.attributes.fleet_details.data[0].attributes.cabin_width,
                    "CABIN LENGTH" : item.attributes.fleet_details.data[0].attributes.cabin_length,
                    "CABIN HEIGHT" : item.attributes.fleet_details.data[0].attributes.cabin_height
                  } : {
                    "REGISTRATION" : "",
                    "SEATS" : 0,
                    "BEDS" : 0,
                    "RANGE" : "",
                    "WIFI" : "",
                    "ATTENDANT" : "",
                    "LUGGAGE" : "",
                    "LAVATORY" : 0,
                    "CABIN WIDTH" : "",
                    "CABIN LENGTH" : "",
                    "CABIN HEIGHT" : ""
                  },
                  "para" : ""
                }
              })
            },
            membership : {
              title : responseJson?.data?.attributes?.membership_details?.data?.attributes?.membership_titles?.data,
              points : responseJson?.data?.attributes?.membership_details?.data?.attributes?.membership_points?.data,
              heading : responseJson?.data?.attributes?.membership_details?.data?.attributes?.heading,
              image : responseJson?.data?.attributes?.membership_details?.data?.attributes?.image,
              description : responseJson?.data?.attributes?.membership_details?.data?.attributes?.description,
            },
            about : {
              title : responseJson?.data?.attributes?.about?.data?.attributes?.title,
              content : responseJson?.data?.attributes?.about?.data?.attributes?.about_contents?.data.sort((a:any,b:any) => parseInt(a.id) - parseInt(b.id))
            },
            headLine : responseJson?.data?.attributes?.headlines?.data,
            footLine  : responseJson?.data?.attributes?.footlines?.data,
            founderContent : {
              title : [{attributes:{title:responseJson?.data?.attributes?.founder_content?.data?.attributes?.title}}],
              description : responseJson?.data?.attributes?.founder_content?.data?.attributes?.description
            },
            mainContent : {
              title : responseJson?.data?.attributes?.discover_main_content?.data?.attributes?.discover_main_content_titles?.data,
              description : responseJson?.data?.attributes?.discover_main_content?.data?.attributes?.description,
              heading : responseJson?.data?.attributes?.discover_main_content?.data?.attributes?.heading,
              image : responseJson?.data?.attributes?.discover_main_content?.data?.attributes?.image
            },
            secondaryContent : {
              title : responseJson?.data?.attributes?.discover_secondary_content?.data?.attributes?.discover_secondary_content_titles?.data.sort((a:any,b:any) => parseInt(a.id) - parseInt(b.id)),
              image : responseJson?.data?.attributes?.discover_secondary_content?.data?.attributes?.image
            }
          })
        }
      }

      if (apiRequestCallId == this.fetchFoundersDataMsgId) {
        if (errorReponse) {
          console.error(errorReponse);
        } else {
          this.setState({
            founders : responseJson.data
          })
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.fetchDiscoverPageData()
    this.fetchFounders()
    window.addEventListener('resize', this.handleResize);
    // Customizable Area End
  }

  // Customizable Area Start

  handleResize() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  fetchDiscoverPageData() : void {
    this.setState({
      loading : true
    })

    const header = {
      "Content-Type": 'application/json',
    };

    const httpBody = {};

    const msg = makeHttpMessage({
      header,
      httpBody,
      httpMethod: 'GET',
      url: configJSON.fetchDiscoverAPI,
    });

    this.fetchDiscoverPageDataMsgId = msg.messageId;
    runEngine.sendMessage(msg.id, msg);
  }

  fetchFounders() {
    const header = {
      "Content-Type" : 'application/json'
    };

    const httpBody = {}

    const msg = makeHttpMessage({
      header,
      httpBody,
      httpMethod : 'GET',
      url : configJSON.fetchFoundersAPI
    });

    this.fetchFoundersDataMsgId = msg.messageId;

    runEngine.sendMessage(msg.id, msg);
  }
  // Customizable Area End
}
